<template>
    <div>
        <div class="current-page">电子卷宗</div>
        <div class="search">
            <ul class="search-list">
                <li class="search-item">
                    <span class="key">修改日期:</span>
                    <div class="value">
                        <el-date-picker 
                        clearable @keydown.native.enter="timeChange" size="small" unlink-panels 
                        v-model="time" type="daterange" 
                        range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" @change="timeChange"
                        value-format="yyyy-MM-dd"></el-date-picker>
                    </div>
                </li>
                <li class="search-item">
                    <span class="key">法院:</span>
                    <div class="value">
                        <el-select size="small" v-model="search.courtId" placeholder="请选择法院" clearable @keydown.native.enter="handleQuery">
                            <el-option v-for="item in courtList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                        </el-select>
                    </div>
                </li>
                <li class="search-item">
                    <span class="key">案件类型:</span>
                    <div class="value">
                        <el-select size="small" v-model="search.caseTypeId" placeholder="请选择案件类型" clearable @keydown.native.enter="handleQuery">
                            <el-option v-for="item in caseTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </div>
                </li>
                <li class="search-item">
                    <span class="key">案件状态:</span>
                    <div class="value">
                        <!-- <el-input clearable size="small" v-model="search.closed" placeholder="请输入案号"></el-input> -->
                        <el-select clearable size="small" v-model="search.closed" placeholder="请选择案件状态" @keydown.native.enter="handleQuery">
                            <el-option value="Y" label="已结案"></el-option>
                            <el-option value="N" label="未结案"></el-option>
                        </el-select>
                    </div>
                </li>
                <li class="search-item">
                    <span class="key">归档状态:</span>
                    <div class="value">
                        <!-- <el-input clearable size="small" v-model="search.closed" placeholder="请输入案号"></el-input> -->
                        <el-select clearable size="small" v-model="search.archiveStatus" placeholder="请选择归档状态" @keydown.native.enter="handleQuery">
                            <el-option v-for="item in archiveStatusList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                        </el-select>
                    </div>
                </li>
                <li class="search-item">
                    <span class="key">关键字:</span>
                    <div class="value">
                        <el-input clearable size="small" v-model="search.caseName" placeholder="请输入案号" @keydown.native.enter="handleQuery"></el-input>
                    </div>
                </li>
                <li>
                    <el-button type="primary" size="small" @click="handleQuery">搜索</el-button>
                    <el-button type="info" size="small" @click="resetSearch">重置</el-button>
                </li>
            </ul>
        </div>
        <div>
            <el-table :data="tableData" height="545" :header-cell-style="{'background-color': '#EDEDED','text-align':'center'}" :cell-style="{'text-align':'center'}" v-loading="loading">
                <el-table-column min-width="100" label="序号">
                    <template slot-scope="scope">
                        <div>{{(search.current - 1) * search.size + scope.$index + 1}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="caseName" label="案号" width="280"> </el-table-column>
                <el-table-column prop="court.name" label="所属法院" width="300"></el-table-column>
                <el-table-column prop="caseType.name" label="案件类型"></el-table-column>
                <el-table-column label="案件状态">
                    <template slot-scope="scope">
                        <div>{{scope.row.closed ? '已结案' : '未结案'}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="归档状态">
                    <template slot-scope="scope">
                        <div v-html="gdStatus(scope.row)">{{}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="courtRoom.name" label="承办庭室" width="120"></el-table-column>
                <el-table-column prop="judge.name" label="承办人" width="160"></el-table-column>
                <!-- <el-table-column prop="cbr_name" label="当事人"></el-table-column> -->
                <el-table-column prop="updateTime" label="修改时间" width="160">
                    <template slot-scope="data">
                        <span>{{data.row.updateTime && data.row.updateTime.slice(0,-3)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="data">
                        <button v-for="(item,index) in dirTypeList" :key="index" class="btn btn-text" @click="toRead(data.row,item)">{{item.name}}</button>
                    </template>
                </el-table-column>
                <template slot="empty">
                    {{hasData}}
                </template>
            </el-table>

        </div>

        <div class="footer">
            <el-pagination background 
                layout="total, sizes, prev, pager, next , jumper" 
                :total="total"
                @size-change="handleQuery"
                @current-change="getAllCaseInfo"
                :page-size.sync="search.size"
                :current-page.sync="search.current"
            > </el-pagination>
        </div>
    </div>
</template>

<script>
import { getAllCaseInfo , getOrgList , getDict} from '@/api/commonAPI.js';
import { getDirTypeList } from '@/api/caseReading.js';

export default {
    data(){
        return {
            search: {
                courtId: '',
                caseTypeId: '',
                current: 1,
                size: 20,
                updateStartTime: null,
                updateEndTime: null,
                caseName: '',
                closed: '',
                archiveStatus: ''
            },
            total: 0,
            time: [],
            tableData: [],
            dirTypeList: [],
            hasData: '',
            courtList: [],
            caseTypeList: [],
            gdDictList: [],
            loading: false,
        }
    },
    mounted(){
        this.getAllCaseInfo();
        this.getDirTypeList();
        this.getCourtList();
        this.getCaseTypeList();
    },
    computed: {
        archiveStatusList(){
            return this.$store.getters.dictMap.gd_status;
        }
    },
    methods:{
        handleQuery(){
            this.search.current = 1;
            this.getAllCaseInfo();
        },
        resetSearch(){
            Object.keys(this.search).forEach(key => {
                this.search[key] = '';
            })
            this.search.page = 1;
            this.search.size = 10;
            this.time = [];
            this.handleQuery();
        },
        getAllCaseInfo(){
            this.loading = true;
            this.hasData = '';
            getAllCaseInfo(this.search).then(res => {
                this.tableData = res.records;
                this.total = res.total;
                if(!res.total){
                    this.hasData = '暂无数据';
                }
            }).catch(err => {
                this.tableData = [];
                this.total = 0;
                this.hasData = '暂无数据';
            }).finally(() => {
                this.loading = false;
            })
        },
        getDirTypeList(){
            getDirTypeList().then(res => {
                this.dirTypeList = res;
            })
        },
        getCourtList(){
            getOrgList().then(res => {
                this.courtList = res.filter(item => item.type === 'COURT');
            })
        },
        getCaseTypeList(){
            getDict({category: 'case_type,gd_status'}).then(res => {
                this.caseTypeList = res[0];
                this.gdDictList = res[1];
            })
        },
        gdStatus(data){
            const archiveStatus = data.extra && data.extra.archive_status;
            const item = this.gdDictList.find(item => item.value === archiveStatus);
            if(item){
                let color = '';
                switch(item.value){
                    case "1" : color = '#FEAA45'; break;
                    case "2" : color = '#101010'; break;
                    case "3" : color = '#27B148'; break;
                    case "4" : color = '#FF2525'; break;
                }
                // return this.$createElement('div',{style: {'color':color}},item.label);
                return `<div style="color:${color}">${item.label}</div>`;
            }else{
                return '--';
            }
        },
        timeChange(){
            this.search.updateStartTime = this.time && this.time[0]; 
            this.search.updateEndTime = this.time && this.time[1];
        },
        toRead(data,category){
            let routeData = this.$router.resolve(`/caseReading?caseId=${data.caseId}&dirCategory=${category.category}`);
            window.open(routeData.href);
        },
    }
}
</script>

<style scoped lang='less'>
    .search-list{
        display: flex;
            flex-wrap: wrap;
        padding: 20px;
        .search-item{
            display: flex;
            align-items: center;
            margin-right: 30px;
            margin-bottom: 10px;
            .key{
                margin-right: 10px;
            }
            .value{
                // width: 240px;
                /deep/ .el-select{
                    width: 100%;
                    .el-select__tags{
                        flex-wrap: nowrap;
                    }
                }
                /deep/ .el-date-editor{
                    width: 100%;
                }
            }
        }
    }
    .footer{
        margin-top: 50px;
        display: flex;
        justify-content: center;
    }
    /deep/ .el-pagination__editor.el-input{
        width: 90px;
    }
</style>