import service from '@/utils/request';

export const fileDownload = params => {
    return service.get('/caseinfo/generate-pdf',{
        params,
        responseType: 'blob',
        baseURL: '/api_v2'
    });
}

//  刪除文件
export const deleteFile = params => {
    return service.delete('/caseinfo/file/remove',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export const getCaseInfo = params => {
    return service.get('/caseinfo/detail-info',{
        params,
        baseURL: '/api_v2',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
}

export const getCaseDirInfo = params => {
    return service.get('/caseinfo/directory/list',{
        params,
        baseURL: '/api_v2',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
}

export const moveDir = data => {
    return service.patch('/caseinfo/directory/move',data,{
        baseURL: '/api_v2',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export const createDir = data => {
    return service.post('/caseinfo/directory/create',data,{
        baseURL: '/api_v2',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export const modifyDir = data => {
    return service.patch('/caseinfo/directory/modify',data,{
        baseURL: '/api_v2',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export const deleteDir = params => {
    return service.delete('/caseinfo/directory/remove',{
        params,
        baseURL: '/api_v2',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export const getCaseInfoKeyword = params => {
    return service.get('/caseinfo/search',{
        params,
        baseURL: '/api_v2',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export const getRecords = params => {
    return service.get('/caseinfo/logs',{
        params,
        baseURL: '/api_v2',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export const moveFile = data => {
    return service.patch('/caseinfo/file/move',data,{
        baseURL: '/api_v2',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export const sortFile = data => {
    return service.patch('/caseinfo/file/classify',data,{
        baseURL: '/api_v2',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}



export const recycleData = params => {
    return service.get('/caseinfo/file/recycle',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        baseURL: '/api_v2'
    })
}

export const revertFile = params => {
    return service.get('/caseinfo/file/recovery',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        baseURL: '/api_v2'
    })
}

export const isOpenOppugn = params => {     //  查看当前法院是否开通质证
    return service.get('/caseinfo/oppugn/queryIsOpenOppugn',{
        params,
        baseURL: '/api_v2'
    })
}

export const getOppugnCourt = params => {   //  获取开通的法院列表
    return service.get('/caseinfo/oppugn/queryCourtList',{
        params,
        baseURL: '/api_v2'
    })
}

export const createOppugn = data => {   //  新增质证记录
    return service.post('/caseinfo/oppugn/create',data,{
        baseURL: '/api_v2'
    })
}

export const getOppugnData = params => {   //  分页查询质证记录
    return service.get('/caseinfo/oppugn/query-oppugn-page',{
        params,
        baseURL: '/api_v2'
    })
}

export const getJWT = params => {   //  分页查询质证记录
    return service.get('/caseinfo/oppugn/create-jwt',{
        params,
        baseURL: '/api_v2'
    })
}

export const oppugnTime = params => {
    return service.get('/caseinfo/oppugn/query-next-oppugn',{
        params,
        baseURL: '/api_v2'
    })
}

export const savePartyInfo = data => {
    return service.post('/caseinfo/oppugn/save-update-party',data,{
        baseURL: '/api_v2'
    })
}

export const getPartyInfo = params => {
    return service.get('/caseinfo/oppugn/query-party',{
        params,
        baseURL: '/api_v2'
    })
}

export const deletePartyInfo = params => {
    return service.get('/caseinfo/oppugn/delete-case-party',{
        params,
        baseURL: '/api_v2'
    })
}

export const getDirTypeList = params => {
    return service.get('/caseinfo/directory/category',{
        params,
        baseURL: '/api_v2'
    })
}

export function archiving(data){    //  一键归档
    return service.post('/caseinfo/archiving',data,{
        baseURL: '/api_v2',
        responseType: 'blob',
    })
}

export function getTemplate(params){    //  一键归档
    return service.get('/caseinfo/template',{
        params,
        baseURL: '/api_v2',
    })
}

//  获取案件批次信息
export const getSubCase = params => {   
    return service.get('/caseinfo/sub-case-info', {
        params,
    })
}

//  获取案件批次信息
export const trialList = params => {   
    return service.get('/caseinfo/hx/get-on-demand-url', {
        params,
    })
}

//  查询原审案件列表
export const originalCaseList = params => {   
    return service.get('/caseinfo/query-original-case', {
        params,
    })
}

//  查询原审案件列表
export const getWaitRollIn = params => {   
    console.log(params);
    return service.get('/caseinfo/hb-extra/sync-hy-drj', {
        params,
    })
}